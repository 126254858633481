<template>
  <div class="reminders">
    <div class="reminders__header">
      <v-page-title class="reminders__title">
        Напоминания
      </v-page-title>
    </div>
    <div class="reminders__content">
      <reminders-calendar
        :week-calendar-dots-dates="weekRemindersDates"
        :range="range"
        @set-new-range="setNewRange"
      ></reminders-calendar>
      <reminders-week-events
        class="reminders__week-events-section"
        :reminders="reminders"
        :expired-reminders="expiredReminders"
        :range="range"
        @set-event-as-completed="setEventAsCompleted"
        @edit-event="editEvent"
        @delete-event="deleteEvent"
      ></reminders-week-events>
    </div>
    <shows-create-dialog :visible.sync="visibleShowEditDialog" :show-data="editModel" @submitted="loadData" />
    <outgoing-call-edit-dialog
      :visible.sync="visibleCallEditDialog"
      :call-data="editModel"
      @submitted="loadData"
    ></outgoing-call-edit-dialog>
  </div>
</template>

<script>
import VPageTitle from '@/components/common/VPageTitle.vue'
import RemindersCalendar from '@/components/Reminders/Calendar.vue'
import { agentsService, customersService, ownersService, remindersService } from '@/services/http'
import { loadingService } from '@/services/loading'
import RemindersWeekEvents from '@/components/Reminders/WeekEvents.vue'
import { cloneDeep, getWeekRangeByDate } from '@/utils/common'
import { formatDate } from '@/utils/formatters'
import confirmService from '@/services/confirmation'
import noticeService from '@/services/notification'
import { CALL_ROLES } from '@/constants/callsRoles'
import ShowsCreateDialog from '@/components/Shows/CreateDialog.vue'
import OutgoingCallEditDialog from '@/components/Reminders/OutgoingCallEditDialog.vue'

const CURRENT_DAY = new Date()

export default {
  name: 'Reminders',
  components: {
    OutgoingCallEditDialog,
    ShowsCreateDialog,
    RemindersWeekEvents,
    RemindersCalendar,
    VPageTitle
  },
  data() {
    return {
      loading: false,
      reminders: [],
      expiredReminders: [],
      range: getWeekRangeByDate(CURRENT_DAY),
      editModel: null,
      visibleShowEditDialog: false,
      visibleCallEditDialog: false
    }
  },
  computed: {
    weekRemindersDates() {
      return Array.from(new Set(this.reminders.map(reminder => reminder.reminderDate)))
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    },
    range(newRange) {
      if (!this.loading) {
        this.loading = true
        this.fetchReminders({ range: newRange }).finally(() => {
          this.loading = false
        })
      }
    }
  },
  created() {
    this.loading = true

    this.refetchReminders().finally(() => {
      this.loading = false
    })
  },
  methods: {
    loadData() {
      this.loading = true

      this.refetchReminders().finally(() => {
        this.loading = false
      })
    },
    refetchReminders() {
      const fetchRemindersResolver = this.fetchReminders({ range: this.range })
      const fetchExpiredRemindersResolver = this.fetchReminders({ isExpired: true })

      return Promise.all([fetchRemindersResolver, fetchExpiredRemindersResolver])
    },
    setLoading(val) {
      this.loading = val
    },
    setNewRange(newRange) {
      this.range = newRange
    },
    setEventAsCompleted(params) {
      if (!this.loading) {
        this.loading = true

        remindersService
          .setEventAsCompleted(params)
          .then(() => {
            return this.refetchReminders()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    fetchReminders({ range, isExpired }) {
      const formattedDatesRange = range?.map(date => formatDate(date, '-', false, true)) || undefined
      return remindersService.getReminders({ range: formattedDatesRange, isExpired }).then(results => {
        if (isExpired) {
          this.expiredReminders = results
        } else {
          this.reminders = results
        }
      })
    },
    formatShowData(show) {
      if (show.account) {
        const formatShow = cloneDeep(show)
        const { phone, name } = show.account
        if (phone) formatShow.clientPhone = phone
        if (name) formatShow.clientName = name
        return formatShow
      }
      return show
    },

    removeShow(eventId) {
      if (!this.loading) {
        confirmService
          .ask({ title: `Вы точно хотите удалить показ?` })
          .then(() => {
            this.loading = true
            agentsService
              .deleteShow(eventId)
              .then(() => {
                noticeService.success('Показ удален')
              })
              .then(() => {
                return this.refetchReminders()
              })
              .catch(() => {
                noticeService.error('Не удалось удалить показ')
              })
              .finally(() => {
                this.loading = false
              })
          })
          .catch(() => {})
      }
    },
    removeCall(eventId, clientId, clientRole) {
      if (!this.loading) {
        const ROLE_SERVICES_MAP = {
          [CALL_ROLES.CUSTOMER]: customersService,
          [CALL_ROLES.OWNER]: ownersService
        }

        confirmService
          .ask({ title: `Вы точно хотите удалить лид?` })
          .then(() => {
            this.loading = true
            ROLE_SERVICES_MAP[clientRole]
              .deleteHistory(clientId, eventId)
              .then(() => {
                noticeService.success('Лид удален')
              })
              .then(() => {
                return this.refetchReminders()
              })
              .catch(() => {
                noticeService.error('Не удалось удалить лид')
              })
              .finally(() => {
                this.loading = false
              })
          })
          .catch(() => {})
      }
    },
    deleteEvent({ reminderTargetId, client: { role, id }, type }) {
      if (type === 'advert_show') {
        this.removeShow(reminderTargetId)
      } else if (type === 'outgoing_call') {
        this.removeCall(reminderTargetId, id, role)
      }
    },

    editShow({ reminderTargetId }) {
      if (!this.loading) {
        this.loading = true
        agentsService
          .getShowById(reminderTargetId)
          .then(result => {
            this.editModel = this.formatShowData(result)
            this.visibleShowEditDialog = true
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    editCall({ reminderTargetId, client: { role, id, name, phone }, description, reminderDate, reminderTime }) {
      this.editModel = {
        phone,
        name,
        callDate: formatDate(reminderDate, '.'),
        callTime: reminderTime,
        text: description,
        reminderTargetId,
        id,
        role
      }
      this.visibleCallEditDialog = true
    },
    editEvent(eventData) {
      if (eventData.type === 'advert_show') {
        this.editShow(eventData)
      } else if (eventData.type === 'outgoing_call') {
        this.editCall(eventData)
      }
    }
  }
}
</script>
