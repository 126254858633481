<template>
  <date-picker
    v-bind="$attrs"
    :value="value"
    :attributes="attributes"
    :model-config="modelConfig"
    :masks="masks"
    class="v-calendar"
    v-on="$listeners"
  >
    <template #header-left-button>
      <button class="v-calendar__button v-calendar__button--left">
        <v-icon-arrow-left />
      </button>
    </template>
    <template #header-right-button>
      <button class="v-calendar__button v-calendar__button--right">
        <v-icon-arrow-left />
      </button>
    </template>
    <template v-if="$scopedSlots.input" #default="slotData">
      <slot name="input" v-bind="slotData"></slot>
    </template>
  </date-picker>
</template>

<script>
import { DatePicker } from 'v-calendar'
import VIconArrowLeft from '@/components/icons/VArrowLeft.vue'

const DEFAULT_MODEL_CONFIG = {
  type: 'string',
  mask: 'YYYY-MM-DD'
}

const DEFAULT_MASK = {
  weekdays: 'WWW'
}

export default {
  name: 'VCalendar',
  components: {
    DatePicker,
    VIconArrowLeft
  },
  props: {
    value: {
      type: [Array, String, Object, Date],
      default: null
    },
    datesWithShows: {
      type: Array,
      default: () => []
    },
    attributes: {
      type: Array,
      default: () => {}
    },
    masks: {
      type: Object,
      default: () => DEFAULT_MASK
    },
    modelConfig: {
      type: Object,
      default: () => DEFAULT_MODEL_CONFIG
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/media';
@import 'src/assets/scss/mixins';

.v-calendar {
  border: none;
  width: clamp(250px, 40%, 280px);

  @include phone {
    width: 100%;
  }

  ::v-deep .vc-day-box-center-bottom {
    justify-content: flex-end;
    align-items: flex-start;
  }

  ::v-deep .vc-pane {
    font-family: $--font-base;
  }

  ::v-deep .vc-popover-content {
    padding: 0 14px;
  }

  .vc-popover-caret {
    display: none;
  }

  ::v-deep .vc-weekday {
    font-weight: $--font-weight-regular;
    color: $--pitch-black;
    padding-bottom: 15px;
  }

  ::v-deep .vc-day {
    min-height: 45px;
  }

  ::v-deep .vc-day-content {
    width: 40px;
    height: 40px;
    font-size: $--font-size-small;

    &:hover {
      background-color: $--medium-gray;
    }

    &:focus {
      background-color: transparent;
    }
  }

  ::v-deep .vc-header {
    padding-bottom: 12px;
  }

  ::v-deep .vc-title {
    font-weight: $--font-weight-medium;
  }

  ::v-deep .vc-arrows-container {
    padding: 8px 0;
  }

  ::v-deep .vc-arrow:hover {
    background: none;
  }

  ::v-deep .vc-dot {
    position: absolute;
    bottom: 7px;
    left: 17px;

    @include themify() {
      background-color: themed('calendarMain') !important;
    }
  }

  ::v-deep .vc-highlight {
    width: 40px;
    height: 40px;

    @include themify() {
      background-color: themed('calendarMain') !important;
    }
  }

  &__button {
    border: 1px solid $--silver;
    width: 32px;
    height: 32px;
    border-radius: 1.6px;

    .icon--arrow-left {
      fill: $--pitch-black;
    }

    &--right {
      .icon--arrow-left {
        transform: rotate(180deg);
      }
    }

    &:hover {
      @include themify() {
        border-color: themed('calendarMain') !important;
      }
    }
  }
}
</style>
