const observers = new Set()
export function subscribe(fn) {
  observers.add(fn)
}
export function unsubscribe(fn) {
  observers.delete(fn)
}

function notify(value) {
  observers.forEach(fn => fn(value))
}

setInterval(() => {
  notify(new Date())
}, 1000)
