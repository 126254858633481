<template>
  <v-dialog :visible.sync="localVisible" title="Исходящий лид" class="calls-create-dialog">
    <validation-observer v-slot="{ handleSubmit }">
      <v-form body-scroll-lock-ignore @submit.prevent="handleSubmit(onSubmit)">
        <template #default>
          <v-form-row>
            <v-form-field label="ТЕЛЕФОН КЛИЕНТА">
              <v-input-phone v-model="call.phone" disabled> </v-input-phone>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ИМЯ КЛИЕНТА">
              <v-input v-model="call.name" disabled type="text" />
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ДАТА ЛИДА" rules="required">
              <template #default="{ validationErrors }">
                <v-input-date
                  v-model="call.callDate"
                  class="calls-create-dialog__date-input"
                  :clearable="false"
                  format="DD.MM.YYYY"
                  value-type="format"
                  :is-error="!!validationErrors.length"
                />
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ВРЕМЯ ЛИДА" rules="required" separate-label>
              <template #default="{ validationErrors }">
                <div class="edit-show-dialog__time-input">
                  <v-select
                    v-model="call.callTimeHours"
                    class="edit-show-dialog__time-input-part"
                    :options="hourOptions"
                    :is-error="!!validationErrors.length"
                    :clearable="false"
                    append-to-body
                  />
                  <span class="edit-show-dialog__time-separator">:</span>
                  <v-select
                    v-model="call.callTimeMinutes"
                    class="edit-show-dialog__time-input-part"
                    :options="minutesOptions"
                    :is-error="!!validationErrors.length"
                    :clearable="false"
                    append-to-body
                  />
                </div>
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="КОММЕНТАРИЙ">
              <v-textarea v-model="call.text" :rows="3" />
            </v-form-field>
          </v-form-row>
        </template>

        <template #footer>
          <v-button primary type="submit">Сохранить</v-button>
        </template>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import VButton from '@/components/common/VButton.vue'
import { customersService, ownersService } from '@/services/http'
import { cloneDeep, getFirstErrorForFields } from '@/utils/common'
import { loadingService } from '@/services/loading'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VInputDate from '@/components/common/VInputDate.vue'
import { CALL_ROLES } from '@/constants/callsRoles'
import { HISTORY_TYPES } from '@/constants/historyEntryTypes'
import VTextarea from '@/components/common/VTextarea.vue'
import VSelect from '@/components/common/VSelect.vue'

function getEmptyCall() {
  return {
    id: null,
    callDate: null,
    callTimeHours: null,
    callTimeMinutes: null,
    name: '',
    phone: '',
    role: '',
    text: ''
  }
}

const SERVICES_MAP = {
  [CALL_ROLES.OWNER]: ownersService,
  [CALL_ROLES.CUSTOMER]: customersService
}

export default {
  CALL_ROLES,
  name: 'OutgoingCallEditDialog',
  components: {
    VSelect,
    VTextarea,
    VInputDate,
    VDialog,
    VFormField,
    VFormRow,
    VButton,
    VInput,
    VForm,
    VInputPhone
  },
  props: {
    visible: { type: Boolean, required: true },
    callData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => {
    return {
      call: getEmptyCall(),
      loading: false,
      errors: {},
      advertOptionsLoading: false,
      agentOptionsLoading: false,
      advertOptions: [],
      agentOptions: [],
      callSourceOptions: [],
      phoneSearchUser: null
    }
  },
  computed: {
    hourOptions() {
      return Array.from({ length: 24 }, (el, index) => String(index).padStart(2, '0'))
    },
    minutesOptions() {
      return Array.from({ length: 12 }, (el, index) => String(index * 5).padStart(2, '0'))
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    rules() {
      return {
        callDate: `${this.isCreation ? 'required' : ''}`,
        advert: `${this.isCreation ? 'required' : ''}`,
        text: ''
      }
    },
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    callRole() {
      return this.call.role
    }
  },
  watch: {
    loading(val) {
      loadingService.setGlobalLoading(val)
    },
    callData: {
      immediate: true,
      handler() {
        this.resetLocalState()
      }
    },
    visible: {
      immediate: true,
      handler(val) {
        if (val) this.resetLocalState()
        else this.call = getEmptyCall()
      }
    }
  },
  methods: {
    resetLocalState() {
      const time = this.callData?.callTime?.split(':') || null
      const [hours, minutes] = time || ['00', '00']
      const emptyCall = getEmptyCall()

      this.call = {
        ...emptyCall,
        ...cloneDeep(this.callData),
        callTimeHours: hours,
        callTimeMinutes: minutes
      }
      this.errors = {}
    },
    onSubmit() {
      this.loading = true
      const { id, reminderTargetId, callTimeHours, callTimeMinutes, callDate, text } = this.call
      const changedFields = {
        comment: text !== this.callData.text,
        date: callDate !== this.callData.callDate,
        time: [callTimeHours, callTimeMinutes].join(':') !== this.callData.callTime
      }
      const updateParams = {
        text,
        reminderDatetime: `${callDate} ${callTimeHours}:${callTimeMinutes}`,
        type: HISTORY_TYPES.OUTGOING_CALL,
        changedFields
      }
      SERVICES_MAP[this.callRole]
        .updateHistory(id, reminderTargetId, updateParams)
        .then(() => {
          this.$emit('submitted')
          this.localVisible = false
        })
        .catch(error => {
          this.errors = getFirstErrorForFields(error.details)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
