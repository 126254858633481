<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="11" height="11" viewBox="0 0 11 11">
    <path fill="#404042" d="M11 3.33125 9.7075 1.99927 5.5 6.32585 1.2925 1.99927 0 3.33125l5.5 5.66802L11 3.33125Z" />
  </svg>
</template>

<script>
export default {
  name: 'VOpenSelect'
}
</script>
