<template>
  <div class="reminders-week-events">
    <div v-if="expiredReminders.length && isThisWeek" class="reminders-week-events__day">
      <h3 class="reminders-week-events__day-title reminders-week-events__day-title--expired">Просрочено</h3>
      <reminders-event-card
        v-for="event in expiredReminders"
        :key="event.id"
        :event="event"
        is-expired
        @set-event-as-completed="setEventAsCompleted"
        @edit-event="$emit('edit-event', $event)"
        @delete-event="$emit('delete-event', $event)"
      ></reminders-event-card>
    </div>
    <div v-for="({ title, events }, index) in remindersByDays" :key="index" class="reminders-week-events__day">
      <h3 class="reminders-week-events__day-title">{{ title }}</h3>
      <template v-if="events.length">
        <reminders-event-card
          v-for="event in events"
          :key="event.id"
          :event="event"
          @set-event-as-completed="setEventAsCompleted"
          @edit-event="$emit('edit-event', $event)"
          @delete-event="$emit('delete-event', $event)"
        ></reminders-event-card>
      </template>
      <div v-else class="reminders-week-events__no-events">Задач нет</div>
    </div>
  </div>
</template>

<script>
import RemindersEventCard from '@/components/Reminders/EventCard.vue'
import { eachDayOfInterval, format, getISODay, isAfter, isBefore } from 'date-fns'
import { ru } from 'date-fns/locale'
import { getStringCapitalize } from '@/utils/common'

export default {
  name: 'RemindersWeekEvents',
  components: { RemindersEventCard },
  props: {
    reminders: {
      type: Array,
      default: () => []
    },
    expiredReminders: {
      type: Array,
      default: () => []
    },
    range: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    weekDatesByRange() {
      return eachDayOfInterval({ start: this.range[0], end: this.range[1] })
    },
    remindersByDays() {
      return this.weekDatesByRange.map(weekDate => ({
        title: this.getDayTitle(weekDate),
        events: this.getDayEvents(weekDate)
      }))
    },
    isThisWeek() {
      const today = new Date()
      return isAfter(today, this.range[0]) && isBefore(today, this.range[1])
    }
  },
  methods: {
    setEventAsCompleted(params) {
      this.$emit('set-event-as-completed', params)
    },
    getDayTitle(weekDate) {
      const dayTitle = format(weekDate, 'EEEE d MMMM', { locale: ru })
      return getStringCapitalize(dayTitle)
    },
    getDayEvents(weekDate) {
      return this.reminders.filter(reminder => reminder.reminderWeekday === getISODay(weekDate) - 1)
    }
  }
}
</script>
