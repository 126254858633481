<template>
  <div>
    <slot
      name="header"
      :goToNextWeek="goToNextWeek"
      :goToPreviousWeek="goToPreviousWeek"
      :goToCurrentWeek="goToCurrentWeek"
      :pickedDate="pickedDate"
      :setDate="setDate"
    ></slot>
    <el-calendar class="week-calendar" :range="range">
      <template #dateCell="{ date, data }">
        <div
          class="week-calendar__day-cell"
          :class="{
            'week-calendar__day-cell--is-today': isToday(date)
          }"
        >
          <span class="week-calendar__day-name">{{ getDayName(date) }}</span>
          <span
            class="week-calendar__day-number"
            :class="{
              'week-calendar__day-number--past':
                !isSameDay(date, $options.CURRENT_DAY) && isBefore(date, $options.CURRENT_DAY)
            }"
          >
            {{
              data.day
                .split('-')
                .slice(2)
                .join()
            }}
          </span>
          <div v-if="formattedWeekRemindersDates.includes(data.day)" class="week-calendar__dot"></div>
        </div>
      </template>
    </el-calendar>
  </div>
</template>

<script>
import { addDays, getDay, isBefore, isSameDay, isToday } from 'date-fns'
import { formatDate } from '@/utils/formatters'
import { getWeekRangeByDate } from '@/utils/common'

const CURRENT_DAY = new Date()

const DAYS_NAMES = {
  0: 'Вс',
  1: 'Пн',
  2: 'Вт',
  3: 'Ср',
  4: 'Чт',
  5: 'Пт',
  6: 'Сб'
}
export default {
  CURRENT_DAY,
  name: 'VWeekCalendar',
  props: {
    weekCalendarDotsDates: {
      type: Array,
      default: () => []
    },
    selectedDate: {
      type: Date,
      default: new Date()
    },
    range: {
      type: Array,
      default: () => getWeekRangeByDate(new Date())
    }
  },
  computed: {
    pickedDate: {
      get() {
        return this.selectedDate
      },
      set(val) {
        this.$emit('set-date', val)
        this.$emit('set-new-range', getWeekRangeByDate(val))
      }
    },
    formattedWeekRemindersDates() {
      return this.weekCalendarDotsDates.map(reminder => formatDate(reminder, '-', false, true))
    }
  },
  watch: {
    selectedDate: {
      immediate: true,
      handler(val) {
        this.pickedDate = val
      }
    }
  },
  methods: {
    isToday,
    isSameDay,
    isBefore,
    setDate(val) {
      this.pickedDate = new Date(val)
    },
    goToCurrentWeek() {
      this.pickedDate = CURRENT_DAY
    },
    goToNextWeek() {
      this.pickedDate = addDays(this.pickedDate, 7)
    },
    goToPreviousWeek() {
      this.pickedDate = addDays(this.pickedDate, -7)
    },
    getDayName(day) {
      return DAYS_NAMES[getDay(day)]
    }
  }
}
</script>
