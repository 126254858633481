var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reminders-calendar"},[_c('v-week-calendar',_vm._g(_vm._b({attrs:{"week-calendar-dots-dates":_vm.weekCalendarDotsDates,"selected-date":_vm.selectedDate,"range":_vm.range},on:{"set-new-range":_vm.setNewRange,"set-date":_vm.setSelectedDate},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var goToNextWeek = ref.goToNextWeek;
var goToPreviousWeek = ref.goToPreviousWeek;
var goToCurrentWeek = ref.goToCurrentWeek;
return [_c('div',{staticClass:"reminders-calendar__header"},[_c('v-calendar',{staticClass:"reminders-calendar__date-input",class:{ 'reminders-calendar__date-input--loading': _vm.calendarLoading },attrs:{"value":_vm.selectedDate,"trim-weeks":"","clearable":false,"model-config":{},"masks":{ weekdays: 'WWW', input: 'MMMM YYYY' },"attributes":_vm.customAttributes,"to-page":_vm.toPageCalendar},on:{"update:toPage":function($event){_vm.toPageCalendar=$event},"update:to-page":function($event){_vm.toPageCalendar=$event},"input":_vm.setSelectedDate},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var inputValue = ref.inputValue;
var showPopover = ref.showPopover;
var hidePopover = ref.hidePopover;
var updateValue = ref.updateValue;
return [_c('label',[_c('v-input',{staticClass:"reminders-calendar__date-input-field",attrs:{"value":inputValue},on:{"focus":showPopover,"blur":hidePopover,"input":updateValue},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-open-select',{staticClass:"reminders-calendar__date-input-field-append"})]},proxy:true}],null,true)})],1)]}}],null,true)}),_c('div',{staticClass:"reminders-calendar__date-navigation"},[_c('v-button',{staticClass:"reminders-calendar__date-navigation-button",on:{"click":function($event){return goToCurrentWeek()}}},[_vm._v("Текущая неделя")]),_c('div',{staticClass:"reminders-calendar__date-navigation-arrows"},[_c('v-pagination-button',{staticClass:"reminders-calendar__date-navigation-button reminders-calendar__date-navigation-button--arrow",attrs:{"highlighted":""},on:{"click":function($event){return goToPreviousWeek()}}},[_c('v-icon-arrow-left')],1),_c('v-pagination-button',{staticClass:"reminders-calendar__date-navigation-button reminders-calendar__date-navigation-button--arrow",attrs:{"highlighted":""},on:{"click":function($event){return goToNextWeek()}}},[_c('v-icon-arrow-right')],1)],1)],1)],1)]}}])},'v-week-calendar',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }