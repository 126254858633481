var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("header",null,{"goToNextWeek":_vm.goToNextWeek,"goToPreviousWeek":_vm.goToPreviousWeek,"goToCurrentWeek":_vm.goToCurrentWeek,"pickedDate":_vm.pickedDate,"setDate":_vm.setDate}),_c('el-calendar',{staticClass:"week-calendar",attrs:{"range":_vm.range},scopedSlots:_vm._u([{key:"dateCell",fn:function(ref){
var date = ref.date;
var data = ref.data;
return [_c('div',{staticClass:"week-calendar__day-cell",class:{
          'week-calendar__day-cell--is-today': _vm.isToday(date)
        }},[_c('span',{staticClass:"week-calendar__day-name"},[_vm._v(_vm._s(_vm.getDayName(date)))]),_c('span',{staticClass:"week-calendar__day-number",class:{
            'week-calendar__day-number--past':
              !_vm.isSameDay(date, _vm.$options.CURRENT_DAY) && _vm.isBefore(date, _vm.$options.CURRENT_DAY)
          }},[_vm._v(" "+_vm._s(data.day .split('-') .slice(2) .join())+" ")]),(_vm.formattedWeekRemindersDates.includes(data.day))?_c('div',{staticClass:"week-calendar__dot"}):_vm._e()])]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }