<template>
  <div class="reminders-calendar">
    <v-week-calendar
      v-bind="$attrs"
      :week-calendar-dots-dates="weekCalendarDotsDates"
      :selected-date="selectedDate"
      :range="range"
      v-on="$listeners"
      @set-new-range="setNewRange"
      @set-date="setSelectedDate"
    >
      <template #header="{ goToNextWeek, goToPreviousWeek, goToCurrentWeek}">
        <div class="reminders-calendar__header">
          <v-calendar
            :value="selectedDate"
            class="reminders-calendar__date-input"
            :class="{ 'reminders-calendar__date-input--loading': calendarLoading }"
            trim-weeks
            :clearable="false"
            :model-config="{}"
            :masks="{ weekdays: 'WWW', input: 'MMMM YYYY' }"
            :attributes="customAttributes"
            :to-page.sync="toPageCalendar"
            @input="setSelectedDate"
          >
            <template #input="{inputValue, showPopover, hidePopover, updateValue}">
              <label>
                <v-input
                  class="reminders-calendar__date-input-field"
                  :value="inputValue"
                  @focus="showPopover"
                  @blur="hidePopover"
                  @input="updateValue"
                >
                  <template #append>
                    <v-open-select class="reminders-calendar__date-input-field-append"></v-open-select>
                  </template>
                </v-input>
              </label>
            </template>
          </v-calendar>
          <div class="reminders-calendar__date-navigation">
            <v-button class="reminders-calendar__date-navigation-button" @click="goToCurrentWeek()"
              >Текущая неделя</v-button
            >
            <div class="reminders-calendar__date-navigation-arrows">
              <v-pagination-button
                class="reminders-calendar__date-navigation-button reminders-calendar__date-navigation-button--arrow"
                highlighted
                @click="goToPreviousWeek()"
              >
                <v-icon-arrow-left />
              </v-pagination-button>
              <v-pagination-button
                class="reminders-calendar__date-navigation-button reminders-calendar__date-navigation-button--arrow"
                highlighted
                @click="goToNextWeek()"
              >
                <v-icon-arrow-right />
              </v-pagination-button>
            </div>
          </div>
        </div>
      </template>
    </v-week-calendar>
  </div>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import VPaginationButton from '@/components/common/VPaginationButton.vue'
import VIconArrowLeft from '@/components/icons/VArrowLeft.vue'
import VIconArrowRight from '@/components/icons/VArrowRight.vue'
import VCalendar from '@/components/common/VCalendar.vue'
import VWeekCalendar from '@/components/common/VWeekCalendar.vue'
import { remindersService } from '@/services/http'
import { getWeekRangeByDate } from '@/utils/common'
import VInput from '@/components/common/VInput.vue'
import VOpenSelect from '@/components/icons/VOpenSelect.vue'

const COLOR_MARKER_CELL_CALENDAR = '#f6ba20'

export default {
  name: 'RemindersCalendar',
  components: {
    VOpenSelect,
    VInput,
    VWeekCalendar,
    VCalendar,
    VPaginationButton,
    VButton,
    VIconArrowLeft,
    VIconArrowRight
  },
  inheritAttrs: false,
  props: {
    weekCalendarDotsDates: {
      type: Array,
      default: () => []
    },
    range: {
      type: Array,
      default: () => getWeekRangeByDate(new Date())
    }
  },
  data() {
    return {
      month: null,
      year: null,
      monthCalendarDatesWithReminders: [],
      selectedDate: new Date(),
      calendarLoading: false
    }
  },
  computed: {
    toPageCalendar: {
      get() {
        return { month: this.month, year: this.year }
      },
      set(newDate) {
        this.month = newDate.month
        this.year = newDate.year
      }
    },
    customAttributes() {
      return [
        {
          dot: {
            style: {
              backgroundColor: COLOR_MARKER_CELL_CALENDAR
            }
          },
          dates: this.monthCalendarDatesWithReminders
        }
      ]
    }
  },
  watch: {
    toPageCalendar() {
      this.fetchReminderCalendarDates()
    }
  },
  methods: {
    setSelectedDate(newDate) {
      if (newDate) {
        this.selectedDate = newDate
      }
    },
    setNewRange(newRange) {
      this.$emit('set-new-range', newRange)
    },
    fetchReminderCalendarDates() {
      if (!this.calendarLoading) {
        this.calendarLoading = true

        remindersService
          .getReminderCalendarDates({ month: this.month, year: this.year })
          .then(response => {
            this.monthCalendarDatesWithReminders = response
              .filter(item => item.remindersCount > 0)
              .map(item => item.date)
          })
          .finally(() => {
            this.calendarLoading = false
          })
      }
    }
  }
}
</script>
