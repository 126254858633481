<template>
  <div class="reminders-event-card">
    <v-overlay v-if="hasPending">Изменения вступят в силу после проверки.</v-overlay>
    <div
      class="reminders-event-card__content"
      :class="{
        'reminders-event-card__content--without-checkbox': !canChangeEventCompleted
      }"
    >
      <div>
        <v-reminder-checkbox
          v-if="canChangeEventCompleted"
          v-model="isCompleted"
          class="reminders-event-card__checkbox"
          :expired="isEventExpired"
        >
        </v-reminder-checkbox>
      </div>
      <div class="reminders-event-card__event-info">
        <div class="reminders-event-card__time">{{ eventDateTime }}</div>
        <div class="reminders-event-card__info">
          <span class="reminders-event-card__type">{{ $options.EVENT_TYPES_NAMES_MAP[event.type] }}</span
          >:
          <v-button v-if="editClientRoute" class="reminders-event-card__client-name" is-text :to="editClientRoute"
            >{{ event.client.name }}
          </v-button>
          <span v-else>{{ event.client.name }}</span>
        </div>
        <div class="reminders-event-card__description">{{ event.description }}</div>
      </div>
      <router-link :to="{ name: 'users' }" class="reminders-event-card__agent" @click.stop>
        <v-avatar class="reminders-event-card__agent-avatar" size="small" :src="event.agent.photo" />
        <span class="reminders-event-card__agent-name">
          {{ event.agent.name }}
        </span>
      </router-link>
      <v-popover-actions v-if="!hasPending" placement="bottom-end" class="reminders-event-card__menu">
        <v-button-icon>
          <v-icon-dots />
        </v-button-icon>
        <template #actions="{ onClick }">
          <v-button-wide @click="onClick(edit)">Редактировать</v-button-wide>
          <v-button-wide @click="onClick(remove)">Удалить</v-button-wide>
        </template>
      </v-popover-actions>
    </div>
  </div>
</template>

<script>
import VIconDots from '@/components/icons/VDots.vue'
import VAvatar from '@/components/common/VAvatar.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import { CALL_ROLES } from '@/constants/callsRoles'
import VButton from '@/components/common/VButton.vue'
import { formatDate } from '@/utils/formatters'
import { isBefore, isSameDay } from 'date-fns'
import { subscribe, unsubscribe } from '@/services/time/time'
import VOverlay from '@/components/common/VOverlay.vue'
import VReminderCheckbox from '@/components/common/VReminderCheckbox.vue'

const EVENT_TYPE_CALL = 'outgoing_call'
const EVENT_TYPE_SHOW = 'advert_show'

const EVENT_TYPES_NAMES_MAP = {
  [EVENT_TYPE_CALL]: 'Звонок',
  [EVENT_TYPE_SHOW]: 'Показ'
}

export default {
  EVENT_TYPES_NAMES_MAP,
  name: 'RemindersEventCard',
  components: {
    VReminderCheckbox,
    VOverlay,
    VButton,
    VButtonWide,
    VButtonIcon,
    VIconDots,
    VPopoverActions,
    VAvatar
  },
  props: {
    event: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isExpired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localCheck: false,
      currentTime: new Date()
    }
  },
  computed: {
    hasPending() {
      return this.event.targetObjectHasPending
    },
    isEventExpired() {
      return (
        this.isExpired ||
        (isBefore(new Date(this.event.reminderDate.split('T')[0]), this.currentTime) && !this.isCompleted)
      )
    },
    canChangeEventCompleted() {
      return (
        isSameDay(new Date(this.event.reminderDate.split('T')[0]), this.currentTime) ||
        isBefore(new Date(this.event.reminderDate.split('T')[0]), this.currentTime)
      )
    },
    editClientRoute() {
      const { role, id } = this.event.client
      if (role && id) {
        const ROLE_LINKS_MAP = {
          [CALL_ROLES.CUSTOMER]: { name: 'customer-edit', params: { customerId: id } },
          [CALL_ROLES.OWNER]: { name: 'owner-edit', params: { ownerId: id } }
        }
        return ROLE_LINKS_MAP[role]
      }
      return null
    },
    eventDateTime() {
      return this.isExpired
        ? `${formatDate(this.event.reminderDate, '.')} ${this.event.reminderTime}`
        : this.event.reminderTime
    },
    isCompleted: {
      get() {
        return this.localCheck
      },
      set(newValue) {
        this.localCheck = newValue
        this.$emit('set-event-as-completed', { isCompleted: newValue, id: this.event.id, eventType: this.event.type })
      }
    }
  },
  watch: {
    'event.isCompleted': {
      immediate: true,
      handler(val) {
        this.localCheck = val
      }
    }
  },
  mounted() {
    subscribe(this.updateCurrentTime)
  },
  beforeDestroy() {
    unsubscribe(this.updateCurrentTime)
  },
  methods: {
    updateCurrentTime(val) {
      this.currentTime = val
    },
    edit() {
      this.$emit('edit-event', this.event)
    },
    remove() {
      this.$emit('delete-event', this.event)
    }
  }
}
</script>
